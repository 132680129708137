import { css } from 'styled-components';
import { rhythm } from '../../utils/typography';

const buttonStyle = css`
  display: inline-block;
  border-style: solid;
  border-color: transparent;
  border-width: ${rhythm(1 / 8)};
  border-radius: 0.4375rem;
  background-color: #2096ff;
  box-shadow: none;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  padding: ${rhythm(1 / 2)};
  /* compensate for descenders */
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${rhythm(1 / 2)};
  letter-spacing: 0.05rem;

  &:hover {
    opacity: 0.5;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export { buttonStyle };
