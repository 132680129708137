import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';
import { useLinkColors } from '../hooks';

type LinkStyleProps = {
  linkColor: string;
  underlineColor: string;
};

const StyledColoredLinks = styled(Box)<LinkStyleProps>`
  a.anchor.before {
    text-decoration: none;
    box-shadow: none;
  }

  /* Don't add underline to the autolinked headers */
  a:not(.before):not(.anchor) {
    position: relative;
    color: ${({ linkColor }) => linkColor};
    box-shadow: 0px 0.25rem 0px ${({ underlineColor }) => underlineColor};

    &:hover,
    &.hovered {
      background-color: ${({ underlineColor }) => underlineColor};
    }
  }
`;

type ColoredLinksProps = BoxProps & {
  themeColor: string;
};

/**
 * A `Box` which makes all links in children colored according to a
 * `themeColor`.
 *
 * Example:
 *
 * ```tsx
 * <ColoredLinks themeColor="green">
 *  <Box>
 *    <Heading>Whatever</Heading>
 *    <a href="https://google.com">Google</a>
 *  </Box>
 * </ColoredLinks>
 * ```
 *
 * The link to Google will be green and will have a light green underline.
 * When the link is hovered, a light green background will appear behind the
 * link.
 */
const ColoredLinks: React.FC<ColoredLinksProps> = ({
  themeColor,
  ...restProps
}) => {
  const { linkColor, underlineColor } = useLinkColors(themeColor);

  return (
    <StyledColoredLinks
      linkColor={linkColor}
      underlineColor={underlineColor}
      {...restProps}
    />
  );
};

export { ColoredLinks };
