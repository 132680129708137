import * as React from 'react';
import { Box, BoxProps, Heading, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { buttonStyle } from './Button/buttonStyle';
import { inputStyle } from './Input/inputStyle';

const StyledMailchimpForm = styled(Box)`
  #mc-embedded-subscribe-form {
    margin-bottom: 0;
  }

  .mc-field-group {
    display: flex;
    flex-wrap: wrap;
  }

  #mce-EMAIL {
    ${inputStyle};
  }

  #mc-embedded-subscribe {
    ${buttonStyle};
  }
`;

type MailchimpFormProps = BoxProps & {
  /**
   * Whether to show or hide the subscribe text. If `true`, only the form
   * inputs will be shown.
   */
  hideSubscribeText?: boolean;
};

/**
 * A form that allows users to sign up for the Natecation Mailchimp list.
 * Mostly copied from the Mailchimp admin dashboard's form builder.
 */
const MailchimpForm: React.FC<MailchimpFormProps> = ({
  hideSubscribeText = false,
  ...restProps
}) => {
  return (
    <StyledMailchimpForm {...restProps}>
      {!hideSubscribeText && (
        <Box>
          <Heading mb={3}>Subscribe</Heading>
          <Text my={2}>
            Updates, new posts, etc. sent three or four times per year.
          </Text>
        </Box>
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <div id="mc_embed_signup">
              <form action="https://nathanhleung.us20.list-manage.com/subscribe/post?u=c67e30bfb995f5af113c810c5&amp;id=a1af3860ec" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll">
                  <div class="mc-field-group">
                    <input type="email" value="" placeholder="Type your email address" name="EMAIL" class="required email" id="mce-EMAIL">
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" onclick="ga('send', 'event', { eventCategory: 'Subscribe', eventAction: 'Subscribe' })">
                  </div>
                  <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                  </div>
                  <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input type="text" name="b_c67e30bfb995f5af113c810c5_a1af3860ec" tabindex="-1" value="" style="background-color: white;">
                  </div>
                </div>
              </form>
            </div>
          `,
        }}
      />
    </StyledMailchimpForm>
  );
};

export { MailchimpForm };
