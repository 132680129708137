/**
 * Selects all dropdown toggles
 */
function getAllDropdownToggles() {
  const dropdownToggles = document.querySelectorAll(
    '.natecation-dropdown-toggle'
  );
  return dropdownToggles;
}

/**
 * Gets the dropdown id corresponding to the given dropdown toggle
 * node.
 *
 * @param {Node} dropdownToggle
 */
function getDropdownIdFromDropdownToggle(dropdownToggle) {
  const dropdownId = dropdownToggle.dataset.dropdownId;
  return dropdownId;
}

/**
 * Toggles the dropdown content corresponding to the given id.
 *
 * @param {String} dropdownId the id of the dropdown content to toggle
 */
function toggleDropdownContent(dropdownId) {
  const dropdownDiv = document.getElementById(
    `natecation-dropdown-${dropdownId}`
  );
  const dropdownToggle = document.getElementById(
    `natecation-dropdown-toggle-${dropdownId}`
  );

  if (dropdownDiv) {
    if (dropdownDiv.classList.contains('visible')) {
      dropdownDiv.classList.remove('visible');
      dropdownToggle.innerText = 'Show';
    } else {
      dropdownDiv.classList.add('visible');
      dropdownToggle.innerText = 'Hide';
    }
  }
}

module.exports = () => {
  const dropdownToggles = getAllDropdownToggles();

  dropdownToggles.forEach(dropdownToggle => {
    dropdownToggle.addEventListener('click', function() {
      const dropdownId = getDropdownIdFromDropdownToggle(this);
      toggleDropdownContent(dropdownId);
    });
  });
};
