import { Link } from 'gatsby';
import * as React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { NavLinks } from './NavLinks';

const Brand = styled.h2`
  @media (min-width: 992px) {
    display: block;
  }

  & > a {
    box-shadow: none;
    text-decoration: none;
    color: inherit;
    opacity: 1;

    @media (min-width: 992px) {
      opacity: 0.25;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

type HeaderProps = {
  atRoot: boolean;
  title: string;
  inverted?: boolean;
};

const Header: React.FC<HeaderProps> = ({ atRoot, title, inverted = false }) => {
  return (
    <>
      <Flex
        padding={[2, 4]}
        paddingY={4}
        justifyContent="center"
        alignItems="center"
        backgroundColor={inverted ? '#000000' : '#ffffff'}
        color={inverted ? '#ffffff' : '#000000'}
        sx={{
          flexDirection: ['column', 'row'],
          justifyContent: ['center'],
        }}
      >
        <Flex justifyContent="center" pb={[3, 0]}>
          {/* Don't show brand on index page */}
          {!atRoot && (
            <Brand
              style={{ textAlign: 'center', marginTop: 0, marginBottom: 0 }}
            >
              <Link to={'/'}>{title}</Link>
            </Brand>
          )}
        </Flex>
        <Box marginLeft={[0, 'auto']} alignSelf="center">
          <NavLinks />
        </Box>
      </Flex>
    </>
  );
};

export { Header };
