import * as React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { rhythm } from '../../utils/typography';

const StyledFooter = styled.footer`
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
  background: #fff;
  padding-bottom: ${rhythm(2)};

  @media (max-width: 959px) {
    padding: ${rhythm(1 / 2)};
  }
`;

const FooterHeading = styled.h5`
  font-size: ${rhythm(1 / 2)};
  opacity: 0.15;
`;

const Icon = styled.span`
  font-size: ${rhythm(1)};
  margin-left: ${rhythm(3 / 4)};
  color: black;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 959px) {
    margin-left: ${rhythm(1 / 2)};
  }
`;

interface NavItemProps {
  inline?: boolean;
}

const NavItem = styled.a<NavItemProps>`
  font-size: ${rhythm(3 / 4)};
  line-height: ${rhythm(5 / 4)};
  opacity: 0.25;
  color: black;
  text-decoration: none;
  border-bottom-width: 0;
  box-shadow: none;
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  font-weight: bold;

  &:hover {
    opacity: 1;
  }
`;

const Copyright = styled.p`
  font-size: ${rhythm(1 / 2)};
  padding-top: ${rhythm(3 / 2)};
  text-align: center;
  opacity: 0.15;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
`;

const INTERNAL_NAV_ITEMS = [
  {
    children: 'Home',
    href: '/',
  },
  {
    children: 'Tour',
    href: '/tour',
  },
  {
    children: 'Trips',
    href: '/trips',
  },
  {
    children: 'Now',
    href: '/now',
  },
];

const EXTERNAL_NAV_ITEMS = [
  {
    href: 'https://n8c.co/gh',
    children: (
      <Icon>
        <i className="fab fa-github" />
      </Icon>
    ),
  },
  {
    href: 'https://n8c.co/li',
    children: (
      <Icon>
        <i className="fab fa-linkedin" />
      </Icon>
    ),
  },
  {
    href: 'https://n8c.co/tw',
    children: (
      <Icon>
        <i className="fab fa-twitter" />
      </Icon>
    ),
  },
  {
    href: 'https://n8c.co/pb',
    children: (
      // This one is a little big
      <Icon style={{ fontSize: rhythm(7 / 8) }}>
        <i className="fas fa-mountain" />
      </Icon>
    ),
  },
  {
    href: 'mailto:n@n8c.co',
    children: (
      // This one is a little big
      <Icon>
        <i className="fas fa-envelope" />
      </Icon>
    ),
  },
];

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <Box marginX="auto" maxWidth={['calc(100% - 20px)', rhythm(32)]}>
        <Flex>
          <Box flex={1}>
            <FooterHeading>On This Site</FooterHeading>
            {INTERNAL_NAV_ITEMS.map(navItem => (
              <NavItem key={navItem.href} {...navItem} />
            ))}
          </Box>
          <Box flex={1} textAlign="right">
            <FooterHeading>External Links</FooterHeading>
            <Flex justifyContent="flex-end">
              {EXTERNAL_NAV_ITEMS.map(navItem => (
                <NavItem key={navItem.href} {...navItem} />
              ))}
            </Flex>
          </Box>
        </Flex>
        <Box>
          <Copyright>
            &copy;{new Date().getFullYear()} Nathan H. Leung
          </Copyright>
          <Text
            opacity={0.2}
            marginTop={4}
            marginBottom={[4, 0]}
            lineHeight={1}
          >
            <small>
              This site represents the author's opinion and is for informational
              purposes only. You should not construe this information as legal,
              tax, investment, or financial advice. Nothing on this site is a
              solicitation to buy or sell securities. You should consult your
              own legal, tax, investment, or financial advisers before engaging
              in any transaction.
            </small>
          </Text>
        </Box>
      </Box>
    </StyledFooter>
  );
};

export { Footer };
