import { BigNumber, Contract, ethers } from 'ethers';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { Box, Flex } from 'rebass/styled-components';
import Web3Modal from 'web3modal';
import {
  hoodieBack,
  hoodieFront,
  physicalHoodies,
} from '../../assets/images/tour';
import { abi } from '../../generated/Natecation.json';
import { useScript } from '../../hooks';
import { Button } from '../Button/';
import { TourCountdown } from './TourCountdown';

const INFURA_ID = process.env.GATSBY_INFURA_ID;
const ADD_CHAIN_RPC_URL = process.env.GATSBY_ADD_CHAIN_RPC_URL ?? '';
const NFT_CHAIN_ID = Number(process.env.GATSBY_NFT_CHAIN_ID ?? 0);
const NFT_CONTRACT_ADDRESS = process.env.GATSBY_NFT_CONTRACT_ADDRESS ?? '';
const BLOCK_EXPLORER_URL = process.env.GATSBY_BLOCK_EXPLORER_URL ?? '';
const OPENSEA_COLLECTION_URL = process.env.GATSBY_OPENSEA_COLLECTION_URL ?? '';

const missingEnvVars = [
  [INFURA_ID, 'GATSBY_INFURA_ID'],
  [NFT_CHAIN_ID, 'GATSBY_NFT_CHAIN_ID'],
  [NFT_CONTRACT_ADDRESS, 'GATSBY_NFT_CONTRACT_ADDRESS'],
  [BLOCK_EXPLORER_URL, 'GATSBY_BLOCK_EXPLORER_URL'],
  [OPENSEA_COLLECTION_URL, 'GATSBY_OPENSEA_COLLECTION_URL'],
].filter(it => typeof it[0] === 'undefined');

if (missingEnvVars.length > 0) {
  throw new Error(
    `Undefined environment variables: ${missingEnvVars
      .map(it => it[1])
      .join(',')}`
  );
}

type ShoppingModalContentProps = {
  launchDate: Date;
};

const ShoppingModalContent: React.FC<ShoppingModalContentProps> = ({
  launchDate,
}) => {
  const walletConnectScriptStatus = useScript(
    'https://unpkg.com/@walletconnect/web3-provider@1.6.5/dist/umd/index.min.js'
  );

  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [buying, setBuying] = useState(false);
  const [changingNetwork, setChangingNetwork] = useState(false);
  const [message, setMessage] = useState('');
  const [address, setAddress] = useState<string>();
  const [chainId, setChainId] = useState<number>();
  const [nftContract, setNftContract] = useState<Contract>();
  const [nftPriceWei, setNftPriceWei] = useState<BigNumber>();
  const [maticPriceCents, setMaticPriceCents] = useState<number>();
  const [buyTransactionHash, setBuyTransactionHash] = useState<string>();

  const nftPriceDollars = Math.ceil(
    BigNumber.from(maticPriceCents ?? 0)
      .mul(nftPriceWei ?? BigNumber.from(0))
      .div(BigNumber.from(ethers.utils.parseEther('1')))
      .toNumber() / 100
  );

  const web3ModalRef = useRef<Web3Modal>();

  useEffect(() => {
    if (walletConnectScriptStatus === 'ready') {
      web3ModalRef.current = new Web3Modal({
        cacheProvider: false,
        providerOptions: {
          walletconnect: {
            // Was causing issues when bundling, so we're getting it from CDN
            // and including via a script tag.
            package: window.WalletConnectProvider.default,
            options: {
              infuraId: INFURA_ID,
              rpc: {
                137: `https://polygon-mainnet.infura.io/v3/${INFURA_ID}`,
                80001: `https://polygon-mumbai.infura.io/v3/${INFURA_ID}`,
              },
            },
          },
        },
      });
    }
  }, [walletConnectScriptStatus]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        }
      );
      const data = await response.json();
      setMaticPriceCents(
        // If we don't round, we may get a floating point error when
        // converting to/from `BigNumber` and ether values.
        //
        // Example:
        //
        // ```js
        // console.log(2.55 * 100);
        // => 254.99999999999997
        // ```
        //
        // See this issue: https://github.com/ethers-io/ethers.js/issues/648
        Math.round(Number(data['matic-network']['usd']) * 100)
      );
    })();
  }, []);

  async function onConnect() {
    setMessage('');
    setConnecting(true);
    if (web3ModalRef.current) {
      try {
        const provider = await web3ModalRef.current.connect();
        setConnected(true);
        provider.on('accountsChanged', async (accounts: string[]) => {
          setAddress(accounts[0]);
        });
        provider.on('chainChanged', async (chainId: number) => {
          setChainId(Number(chainId));
        });
        provider.on('disconnect', () => {
          setConnected(false);
          setChainId(undefined);
          setAddress(undefined);
        });
        // 'any' from https://github.com/NoahZinsmeister/web3-react/issues/127
        const web3 = new ethers.providers.Web3Provider(provider, 'any');
        setAddress((await web3.listAccounts())[0]);
        setChainId(Number((await web3.getNetwork()).chainId));

        const signer = web3.getSigner();
        const nftContract = new ethers.Contract(
          NFT_CONTRACT_ADDRESS,
          abi,
          signer
        );
        setNftContract(nftContract);
        const nftPrice = await nftContract.getPrice();
        setNftPriceWei(nftPrice);
      } catch (e) {
        setMessage("We couldn't connect. Please try connecting again.");
        console.error(e);
      } finally {
        setConnecting(false);
      }
    }
  }

  useEffect(() => {
    if (web3ModalRef.current?.cachedProvider) {
      onConnect();
    }
  }, [web3ModalRef.current]);

  async function onChangeNetwork() {
    setMessage('');
    setChangingNetwork(true);
    try {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${NFT_CHAIN_ID.toString(16)}`,
            chainName: 'Polygon',
            nativeCurrency: {
              name: 'MATIC',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: [ADD_CHAIN_RPC_URL],
            blockExplorerUrls: [BLOCK_EXPLORER_URL],
            iconUrls: [
              'https://cryptologos.cc/logos/polygon-matic-logo.svg?v=013',
            ],
          },
        ],
      });
    } catch (e) {
      setMessage(
        "We couldn't switch the network. Please try again or manually switch to the Polygon network (chain 137)."
      );
      console.error(e);
    } finally {
      setChangingNetwork(false);
    }
  }

  async function onBuyNft() {
    setBuying(true);
    setMessage('');
    setBuyTransactionHash('');
    if (nftContract) {
      const nftPrice = await nftContract.getPrice();
      try {
        const { hash } = await nftContract.claim({
          value: nftPrice,
        });
        setBuyTransactionHash(hash);
      } catch (e) {
        if (e && (e as any).code === 4001) {
          setMessage('NFT purchase cancelled by user.');
        } else if (e && (e as any).code === -32603) {
          if ((e as any).data?.code === -32000) {
            setMessage(
              "You don't have enough funds in your wallet to complete this transaction! You need to buy some MATIC."
            );
          } else {
            console.error(e);
            setMessage(
              'An error occurred. Try refreshing the page and trying again.'
            );
          }
        }
      } finally {
        setBuying(false);
      }
    } else {
      setMessage(
        "Couldn't connect to the blockchain. Try refreshing the page and trying again."
      );
      setBuying(false);
    }
  }

  return (
    <Box
      color="white"
      textAlign="center"
      backgroundColor="black"
      padding={[3, 5]}
    >
      <h1 style={{ marginTop: 0 }}>Get NFTs + Merch</h1>
      {/* Hide the top section once the transaction goes through. */}
      {!buyTransactionHash && (
        <>
          <Flex
            paddingBottom={3}
            width={['100%', '100%']}
            margin="0 auto"
            flexDirection={['column', 'row']}
          >
            <Flex
              backgroundColor="white"
              alignItems="center"
              padding={3}
              style={{ borderRadius: '0.4375rem' }}
              flex={1}
              as="a"
              href={hoodieFront}
              target="_blank"
            >
              <img
                src={hoodieFront}
                style={{ display: 'block', marginBottom: 0 }}
              />
            </Flex>
            <Box paddingX={[0, 3]} paddingY={[2, 0]} />
            <Flex
              backgroundColor="white"
              alignItems="center"
              padding={3}
              style={{ borderRadius: '0.4375rem' }}
              flex={1}
              as="a"
              href={physicalHoodies}
              target="_blank"
            >
              <img
                src={physicalHoodies}
                style={{
                  display: 'block',
                  marginBottom: 0,
                  borderRadius: '0.4375rem',
                }}
              />
            </Flex>
          </Flex>{' '}
          <small>(tap/click images to see detail)</small>
          <Box width={['100%', '70%']} paddingTop={3} margin="0 auto">
            <p>
              There will be up to 1000 total Natecation NFTs minted. See
              examples on{' '}
              <a target="_blank" href={OPENSEA_COLLECTION_URL}>
                OpenSea
              </a>
              .
              <br />
              <br />
              NFTs are (optionally) redeemable for physical hoodies — contact
              us.
              <br />
              <br />
              Need a Polygon crypto wallet? Follow the{' '}
              <a
                href="https://docs.google.com/presentation/d/1LunQrlpXbjoONDDxRevXo36nhEKVLXT-tD9pUANVtjA/edit?usp=sharing"
                target="_blank"
              >
                instructions here
              </a>
              .
            </p>
          </Box>
          <TourCountdown launchDate={launchDate} />
        </>
      )}
      <Countdown
        date={launchDate}
        renderer={({ completed }) =>
          completed && (
            <Flex
              flexDirection={['column', 'row']}
              alignItems="center"
              justifyContent="center"
            >
              <Button onClick={onConnect} disabled={connected} as="button">
                1.{' '}
                {connected
                  ? 'Connected ✅'
                  : connecting
                  ? 'Connecting...'
                  : 'Connect Crypto Wallet'}
              </Button>
              <Box paddingY={[1, 0]} paddingX={[0, 3]} />
              <Button
                onClick={onChangeNetwork}
                disabled={!connected || chainId === NFT_CHAIN_ID}
                as="button"
              >
                2.{' '}
                {chainId === NFT_CHAIN_ID
                  ? 'Verified ✅'
                  : changingNetwork
                  ? 'Verifying...'
                  : 'Verify Network Settings'}
              </Button>
              <Box paddingY={[1, 0]} paddingX={[0, 3]} />
              <Button
                onClick={onBuyNft}
                disabled={
                  !connected ||
                  chainId !== NFT_CHAIN_ID ||
                  buying ||
                  !!buyTransactionHash
                }
                as="button"
              >
                3.{' '}
                {buyTransactionHash
                  ? 'Purchased ✅'
                  : buying
                  ? 'Buying...'
                  : `Buy NFT ${
                      nftPriceDollars > 0 ? `(~$${nftPriceDollars})` : ''
                    }`}
              </Button>
            </Flex>
          )
        }
      />

      <Box paddingTop={4}>
        {buyTransactionHash && (
          <p>
            Your purchase was successfully submitted to the blockchain!{' '}
            <a
              target="_blank"
              href={`${BLOCK_EXPLORER_URL}/tx/${buyTransactionHash}`}
            >
              View Transaction
            </a>
            <Box marginTop={[1, 0, 0, 0]} />
            <br />
            In a few minutes, your new NFT should show up on your{' '}
            <a target="_blank" href={`https://opensea.io/account`}>
              OpenSea account
            </a>{' '}
            (opensea.io).
          </p>
        )}
        <Countdown
          renderer={({ completed }) => completed && message && <p>{message}</p>}
        />
        <p>
          {/* Hide the explanatory section once transaction goes through */}
          <small>
            {!buyTransactionHash && (
              <span>
                Natecation NFTs are minted on the Polygon blockchain. Each NFT
                is randomly assigned a city, weighted by the length of the
                Natecation in that particular location. The most common
                locations are Lower Gwynedd, PA (33%) and San Francisco, CA
                (31%). The rarest are Mt Rainier, WA (0.23%) and Ocean City, NJ
                (0.23%). There will be up to 1000 total NFTs minted.
              </span>
            )}{' '}
            See{' '}
            <a target="_blank" href={OPENSEA_COLLECTION_URL}>
              Natecation on OpenSea
            </a>{' '}
            and the{' '}
            <a
              target="_blank"
              href={`${BLOCK_EXPLORER_URL}/address/${NFT_CONTRACT_ADDRESS}#code`}
            >
              Natecation contract code
            </a>{' '}
            for details.
          </small>
        </p>
        {/* Hide connection info once transaction goes through */}
        {!buyTransactionHash && (
          <p style={{ marginBottom: 0 }}>
            {connected && (
              <small>
                Account {address} connected to chain {chainId}.
              </small>
            )}
          </p>
        )}
      </Box>
    </Box>
  );
};

export { ShoppingModalContent };
