import { PageProps, graphql } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled, { createGlobalStyle } from 'styled-components';
import { tourLocations, tourWordmark } from '../assets/images/tour';
import { Header, Seo } from '../components';
import { ShoppingModalContent, TourCountdown } from '../components/tour/';
import { TourQueryQuery } from '../generated/graphqlTypes';

type TourStyleProps = {
  modalVisible?: boolean;
};

const TourStyle = createGlobalStyle<TourStyleProps>`
  html {
    overflow-y: ${({ modalVisible }) => (modalVisible ? 'hidden' : 'initial')};
  }

  body {
    background: #000000;
  }
`;

const IconButtonContainer = styled.div`
  display: inline-block;
  font-size: 5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.5 !important;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type IconButtonProps = {
  onClick(): void;
  disabled?: boolean;
};

const PlayIconButton: React.FC<IconButtonProps> = ({ onClick, disabled }) => {
  return (
    <IconButtonContainer
      onClick={disabled ? undefined : onClick}
      className={disabled ? 'disabled' : undefined}
    >
      <i className="fas fa-play-circle" />
      <small
        style={{
          display: 'block',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '0.75rem',
          marginTop: '-1rem',
        }}
      >
        Watch Video
      </small>
    </IconButtonContainer>
  );
};

const ShoppingIconButton: React.FC<IconButtonProps> = ({
  onClick,
  disabled,
}) => {
  return (
    <IconButtonContainer
      onClick={disabled ? undefined : onClick}
      className={disabled ? 'disabled' : undefined}
    >
      <i className="fas fa-shopping-bag" />
      <small
        style={{
          display: 'block',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          fontSize: '0.75rem',
          marginTop: '-1rem',
        }}
      >
        Get NFTs + Merch
      </small>
    </IconButtonContainer>
  );
};

const ResponsiveIFrameVideoWrapper = styled.div`
  /* From https://css-tricks.com/fluid-width-video/ */
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

type ModalProps = {
  visible: boolean;
  onChangeVisible(newVisible: boolean): void;
};

const Modal: React.FC<ModalProps> = ({
  visible,
  onChangeVisible,
  children,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Flex
      alignItems="center"
      backgroundColor="rgba(255,255,255,0.375)"
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      onClick={() => onChangeVisible(false)}
    >
      <Box
        flex={1}
        padding={[3, 5]}
        paddingX={[3, 6]}
        style={{
          overflowY: 'scroll',
          height: '100%',
        }}
      >
        <Box paddingX={[2, 6]} flex={1}>
          <div
            onClick={e => e.stopPropagation()}
            style={{
              border: '1px solid white',
              borderRadius: '0.4375rem',
              padding: '1px',
            }}
          >
            {children}
          </div>
        </Box>
      </Box>
    </Flex>
  );
};

type TourProps = {
  data: TourQueryQuery;
} & PageProps;

const Tour: React.FC<TourProps> = ({ data, location }) => {
  const [tourVideoModalVisible, setTourVideoModalVisible] = useState(false);
  const [shoppingModalVisible, setShoppingModalVisible] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  // Thursday, October 21, 2021 at 9:00pm PDT
  const launchDate = new Date('2021-10-22T04:00:00.000Z');
  // const launchDate = new Date();

  const siteTitle = data?.site?.siteMetadata?.title ?? '';
  const siteDescription = data?.site?.siteMetadata?.description ?? '';

  return (
    <Box
      flex={1}
      backgroundColor="#000000"
      minHeight="100%"
      sx={{ textTransform: 'uppercase' }}
    >
      <Seo
        title={`Tour`}
        description={siteDescription}
        pathname={location.pathname}
      />
      <TourStyle modalVisible={tourVideoModalVisible || shoppingModalVisible} />
      <Box
        color="white"
        paddingX={[3, 3, 6]}
        paddingTop={6}
        paddingBottom={[3, 6]}
      >
        <Flex opacity={buttonClicked ? 1 : 0.7} flexWrap="wrap">
          <Box
            width={['100%', '100%', '50%', '50%']}
            textAlign={['center', 'center', 'left', 'left']}
          >
            <Box marginLeft={[0, 0, '-4%']}>
              <img
                src={tourWordmark}
                style={{
                  width: '100%',
                  height: 'auto',
                  marginBottom: 0,
                }}
              />
            </Box>
            <Box
              fontSize={['3rem', '4rem', '3rem', '4rem']}
              marginTop={['-4.75rem', '-5rem']}
            >
              <h1 style={{ fontSize: 'inherit' }}>Tour</h1>
              <h2 style={{ fontSize: '50%', marginTop: 0, marginBottom: 0 }}>
                2019 – 2021
              </h2>
            </Box>
          </Box>
          <Flex
            width={['100%', '100%', '50%', '50%']}
            margin="0 auto"
            fontSize={['2rem', '3rem', '2rem', '3rem']}
            textAlign={['center', 'center', 'right', 'right']}
            alignItems={['center', 'center', 'flex-end']}
            justifyContent={['center', 'center', 'flex-end']}
          >
            <h1
              style={{
                fontSize: 'inherit',
                marginBottom: 0,
                lineHeight: 1.2,
              }}
            >
              1000 NFTs
              <br />
              25 Cities
              <br />
              <span style={{ fontSize: '120%' }}>1 Natecation</span>
            </h1>
          </Flex>
        </Flex>
        <Box
          width={['100%', '100%', '50%', '60%']}
          margin="0 auto"
          marginTop={5}
          textAlign="center"
        >
          <TourCountdown launchDate={launchDate} />
          <Flex
            alignItems="center"
            justifyContent="center"
            marginTop={[4, 0, 0, 0]}
          >
            <PlayIconButton
              onClick={() => {
                setButtonClicked(true);
                setTourVideoModalVisible(true);
              }}
            />
            <Box padding={[3, 5]} />
            <ShoppingIconButton
              onClick={() => {
                setButtonClicked(true);
                setShoppingModalVisible(true);
              }}
            />
          </Flex>
          <Box marginTop={4}>
            <Text fontSize={1}>
              NFTs redeemable for physical hoodies — contact us to redeem.
            </Text>
            <Text fontSize={1} marginTop={[3, 3, 0, 0]}>
              Need a Polygon crypto wallet? Follow the{' '}
              <a
                href="https://docs.google.com/presentation/d/1LunQrlpXbjoONDDxRevXo36nhEKVLXT-tD9pUANVtjA/edit?usp=sharing"
                target="_blank"
              >
                instructions here
              </a>
              .
            </Text>
          </Box>
        </Box>
        <Box
          width={['100%', '50%']}
          margin="0 auto"
          marginTop={5}
          opacity={buttonClicked ? 1 : 0.7}
        >
          <img src={tourLocations} />
        </Box>
      </Box>
      <Modal
        visible={tourVideoModalVisible}
        onChangeVisible={setTourVideoModalVisible}
      >
        <ResponsiveIFrameVideoWrapper onClick={e => e.stopPropagation()}>
          <iframe
            width="1120"
            height="630"
            src="https://www.youtube-nocookie.com/embed/G3NKoA1dgvA?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </ResponsiveIFrameVideoWrapper>
      </Modal>
      <Modal
        visible={shoppingModalVisible}
        onChangeVisible={setShoppingModalVisible}
      >
        <ShoppingModalContent launchDate={launchDate} />
      </Modal>
    </Box>
  );
};

export default Tour;

export const pageQuery = graphql`
  query TourQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
