/**
 * Selects all footnote reference elements.
 */
function getAllFootnoteReferences() {
  const footnoteReferences = document.querySelectorAll(
    'a.footnote-ref[href*="#fn-"]'
  );
  return footnoteReferences;
}

/**
 * Gets the footnote identifier corresponding to the given footnote reference
 * node.
 *
 * @param {Node} footnoteReference
 */
function getFootnoteIdentifierFromFootnoteReference(footnoteReference) {
  const footnoteIdentifier = footnoteReference.innerText;
  return footnoteIdentifier;
}

/**
 * Shows the definition corresponding to the given footnote.
 *
 * @param {String} footnoteIdentifier the identifier of the footnote definition
 *                                    to show
 */
function showFootnoteDefinition(footnoteIdentifier) {
  const footnoteDiv = document.getElementById(
    `natecation-footnote-${footnoteIdentifier}`
  );
  if (footnoteDiv) {
    footnoteDiv.classList.add('visible');
  }
}

/**
 * Hides the definition corresponding to the given footnote.
 *
 * @param {String} footnoteIdentifier the identifier of the footnote definition
 *                                    to hide
 */
function hideFootnoteDefinition(footnoteIdentifier) {
  const footnoteDiv = document.getElementById(
    `natecation-footnote-${footnoteIdentifier}`
  );
  if (footnoteDiv) {
    footnoteDiv.classList.remove('visible');
  }
}

/**
 * Hides all footnote definitions.
 */
function hideAllFootnoteDefinitions() {
  const footnoteReferences = getAllFootnoteReferences();
  footnoteReferences.forEach(footnoteReference => {
    const footnoteIdentifier = getFootnoteIdentifierFromFootnoteReference(
      footnoteReference
    );
    hideFootnoteDefinition(footnoteIdentifier);
  });
}

module.exports = () => {
  const footnoteReferences = getAllFootnoteReferences();

  footnoteReferences.forEach(footnoteReference => {
    footnoteReference.addEventListener('mouseover', function() {
      const footnoteIdentifier = getFootnoteIdentifierFromFootnoteReference(
        this
      );
      if (window.innerWidth >= 992) {
        hideAllFootnoteDefinitions();
      }
      showFootnoteDefinition(footnoteIdentifier);
    });
  });

  document.addEventListener('scroll', () => {
    if (window.innerWidth >= 992) {
      hideAllFootnoteDefinitions();
    }
  });

  const footnoteDefinitionCloseLinks = document.querySelectorAll(
    '.natecation-footnote-definition-close-link'
  );
  footnoteDefinitionCloseLinks.forEach(footnoteDefinitionCloseLink => {
    footnoteDefinitionCloseLink.addEventListener('click', function() {
      const footnoteIdentifier = this.dataset.footnoteIdentifier;
      hideFootnoteDefinition(footnoteIdentifier);
    });
  });
};
