import * as React from 'react';
import { Box, BoxProps, Flex, Heading, Text } from 'rebass/styled-components';
import { Link } from 'gatsby';
import { ColoredLinks } from './ColoredLinks';

const BIO_ITEMS = [
  {
    color: '#14284B',
    children: (
      <Text>
        grew up in the <Link to="/hs-track/">suburbs of Philadelphia</Link>
      </Text>
    ),
  },
  {
    color: '#2E7DB5',
    children: (
      <Text>
        learned{' '}
        <Link to="/high-school-suspension/">how to code in high school</Link>
      </Text>
    ),
  },
  {
    color: '#FFCB05',
    children: (
      <Text>
        went to the{' '}
        <Link to="/michigan-application/">University of Michigan</Link> for a
        year
      </Text>
    ),
  },
  {
    color: '#ED6723',
    children: (
      <Text>
        <Link to="/why-i-dropped-out/">left school</Link>
      </Text>
    ),
  },
  {
    color: '#F04A00',
    children: (
      <Text>
        <Link to="/summer-in-san-francisco/">moved to San Francisco</Link>
      </Text>
    ),
  },
  {
    color: '#0F9D58',
    children: (
      <Text>
        interned <Link to="/google-internship/">at Google</Link>
      </Text>
    ),
  },
  {
    color: '#885031',
    children: (
      <Text>
        became{' '}
        <Link to="/startup-year-a-review-of-my-year-at-jupiter/">
          employee #1 at a startup
        </Link>
      </Text>
    ),
  },
  {
    color: '#218383',
    children: (
      <Text>
        <Link to="/tour">traveled</Link> and{' '}
        <Link to="/accredited-investor-investing-startups-series-65/">
          invested
        </Link>
      </Text>
    ),
  },
  {
    color: '#2096ff',
    children: (
      <Text>
        <Link to="/now">now</Link> returning to college at UCLA
      </Text>
    ),
  },
];

type BioProps = BoxProps;

const Bio: React.FC<BioProps> = props => {
  return (
    <Box textAlign="center" {...props}>
      <Flex
        alignItems="baseline"
        justifyContent="center"
        pb={[4, 2]}
        flexWrap="wrap"
      >
        <Heading fontSize={[6, 7, 8]} color="black" mx={[2, 3]}>
          Natecation
        </Heading>
        <Heading>from Nathan H. Leung</Heading>
      </Flex>
      <Box>
        <Flex flexWrap="wrap" justifyContent="center">
          {BIO_ITEMS.map((bioItem, index) => (
            // Using `key={index}` is OK since the order of the items will not
            // change
            <ColoredLinks pt={1} px={2} themeColor={bioItem.color} key={index}>
              {bioItem.children}
            </ColoredLinks>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export { Bio };
