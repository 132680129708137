import { Link } from 'gatsby';
import { parse } from 'node-html-parser';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { Flex, FlexProps, Heading } from 'rebass/styled-components';
import styled from 'styled-components';
import { rhythm } from '../utils/typography';

interface TableOfContentsContainerProps {
  hidden: boolean;
}

const TableOfContentsContainer = styled(Flex)<TableOfContentsContainerProps>`
  flex-direction: column;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 6px 8px rgba(0, 0, 0, 0.11),
    0 8px 16px rgba(0, 0, 0, 0.11);
  border-radius: 0.4375rem;
  margin-bottom: 30px;
  max-height: 60%;
  width: 100%;
  overflow-y: auto;
  cursor: ${({ hidden }) => (hidden ? 'pointer' : 'default')};

  &:hover {
    opacity: 100%;
  }

  @media (min-width: 992px) {
    opacity: ${({ hidden }) => (hidden ? '25%' : '100%')};
    width: 250px;
    position: sticky;
    margin-left: 30px;
    margin-bottom: auto;
    top: 1.75rem;
    max-height: calc(100vh - 9.25rem);
  }

  @media (min-width: 1200px) {
    margin-left: 90px;
  }

  li {
    list-style-type: none;
    margin-bottom: 5px;
    font-size: ${rhythm(9 / 16)};

    & > ul {
      margin-top: 0;
    }

    & > p {
      margin-bottom: 0;
    }
  }
`;

const TableOfContentsButton = styled.button`
  border-radius: 0.4375rem;
  cursor: pointer;
  color: #000000;
  border: none;
  background-color: #e8e8e8;
  font-size: 0.8rem;
  padding: 0.4375rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    background-color: #d8d8d8;
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

type TableOfContentsProps = FlexProps & {
  /**
   * The HTML table of contents generated by Remark
   */
  tableOfContents: string;
};

const TableOfContents: React.FC<TableOfContentsProps> = ({
  tableOfContents,
  ...restProps
}) => {
  const [hidden, setHidden] = useState(false);
  const [activeItem, setActiveItem] = useState<HTMLElement>();

  useEffect(() => {
    const activeId = activeItem?.id;
    if (activeId) {
      const activeHref = `#${activeId}`;
      document
        .querySelectorAll('#natecation-table-of-contents a')
        .forEach(a => {
          a.classList.remove('hovered');
        });

      document
        .querySelector(`#natecation-table-of-contents a[href="${activeHref}"]`)
        ?.classList.add('hovered');
    }
  }, [activeItem]);

  const ids = parse(tableOfContents)
    .querySelectorAll('a')
    .map(anchor => anchor.attributes.href)
    .map(href => {
      if (href.startsWith('#')) {
        return href.slice(1);
      }
      return href;
    });

  return (
    // Need to wrap in an extra `<div>` since `position: sticky` doesn't work in
    // Firefox with a flex parent.
    // See https://stackoverflow.com/questions/52137323/position-sticky-works-in-chrome-but-not-in-firefox/52137686
    <div>
      <TableOfContentsContainer
        hidden={hidden}
        onClick={() => {
          if (hidden) {
            setHidden(false);
          }
        }}
        {...restProps}
      >
        {/* Need to wrap in an extra div for proper display on Safari */}
        <div
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Heading fontSize={3}>Contents</Heading>
            <TableOfContentsButton
              onClick={() => setHidden(!hidden)}
              as="button"
            >
              {hidden ? 'Show' : 'Hide'}
            </TableOfContentsButton>
          </div>
        </div>
        <div style={{ display: hidden ? 'none' : 'block' }}>
          <ul>
            <li>
              <p>
                <Link to="/">
                  <i className="fas fa-long-arrow-alt-left" />
                  &nbsp;&nbsp;Back to Home
                </Link>
              </p>
            </li>
          </ul>
          <Scrollspy
            items={ids}
            currentClassName=""
            onUpdate={item => {
              setActiveItem(item);
            }}
          >
            <div
              id="natecation-table-of-contents"
              dangerouslySetInnerHTML={{ __html: tableOfContents }}
            />
          </Scrollspy>
          <ul>
            <li>
              <p>
                <a href="#top">
                  <i className="fas fa-long-arrow-alt-up" />
                  &nbsp;&nbsp;Back to Top
                </a>
              </p>
            </li>
          </ul>
        </div>
      </TableOfContentsContainer>
    </div>
  );
};

export { TableOfContents };
