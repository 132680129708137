import * as React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';

const StyledHoverableOpacity = styled(Box)`
  &:hover {
    opacity: 0.5;
  }
`;

type HoverableOpacityProps = BoxProps;

/**
 * Based on `Box`. On hover, the opacity of the wrapped children of this
 * component is decreased, dimming them.
 *
 * Useful for simulating link-like behavior on arbitrary components. Similar to
 * `TouchableOpacity` in React Native
 * (https://reactnative.dev/docs/touchableopacity).
 */
const HoverableOpacity: React.FC<HoverableOpacityProps> = props => {
  return <StyledHoverableOpacity {...props} />;
};

export { HoverableOpacity };
