import { PageProps, withPrefix } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled, { createGlobalStyle } from 'styled-components';

import initDropdowns from '../../../plugins/gatsby-remark-natecation-dropdowns/init';
import initFootnotes from '../../../plugins/gatsby-remark-natecation-footnotes/init';
import initGalleries from '../../../plugins/gatsby-remark-natecation-galleries/init';
import { rhythm } from '../../utils/typography';
import { ColoredLinks } from '../ColoredLinks';
import { Footer } from './Footer';
import { Header } from './Header';

const SmoothScroll = createGlobalStyle`
  html {
    /* https://gomakethings.com/how-to-animate-scrolling-to-anchor-links-with-one-line-of-css/ */
    scroll-behavior: smooth;
  }

  [id] {
    /**
    * Add 30px margin to every element when smooth scrolling
    * https://gomakethings.com/how-to-prevent-anchor-links-from-scrolling-behind-a-sticky-header-with-one-line-of-css/ 
    */
    scroll-margin-top: 30px;
  }

  /**
  * Disable smooth scrolling when users have prefers-reduced-motion enabled
  */
  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }
`;

interface ContainerProps {
  atRoot: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-left: ${rhythm(3 / 4)};
  padding-right: ${rhythm(3 / 4)};
  padding-bottom: 0;

  @media (min-width: 992px) {
    padding-top: ${({ atRoot }) => (atRoot ? rhythm(1 / 2) : rhythm(1))};
  }

  h1:first-of-type {
    margin-top: 0;
  }
`;

interface ContentProps {
  hasToc: boolean;
}

const Content = styled.div<ContentProps>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: 992px) {
    width: ${({ hasToc }) => (hasToc ? rhythm(32) : rhythm(24))};
  }

  @media (min-width: 1200px) {
    width: ${({ hasToc }) => (hasToc ? rhythm(40) : rhythm(32))};
  }
`;

type LayoutProps = {
  title: string;
  hasToc?: boolean;
  location: PageProps['location'];
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style'>;

const Layout: React.FC<LayoutProps> = ({
  location,
  title,
  children,
  hasToc = false,
  style,
}) => {
  useEffect(() => {
    initFootnotes();
    initDropdowns();
    initGalleries();
  }, []);

  // Disable smooth scroll/automatically start at top when navigating between
  // pages. Wrapped in waterfalling `requestAnimationFrame`s to ensure
  // sequential execution.
  const [smoothScrollEnabled, setSmoothScrollEnabled] = useState(false);
  useEffect(() => {
    setSmoothScrollEnabled(false);

    requestAnimationFrame(() => {
      window.scrollTo(0, 0);

      requestAnimationFrame(() => {
        setSmoothScrollEnabled(true);
      });
    });
  }, [location.pathname]);

  const rootPath = withPrefix('/');
  const atRoot = location.pathname === rootPath;

  return (
    <div style={style}>
      {smoothScrollEnabled && <SmoothScroll />}
      <Header atRoot={atRoot} title={title} />
      <div style={{ position: 'relative' }}>
        <div
          style={{
            backgroundColor: 'white',
          }}
        >
          {/* Same color as subscribe button */}
          <ColoredLinks themeColor="#2096FF">
            <Container atRoot={atRoot}>
              <Content hasToc={hasToc}>{children}</Content>
            </Container>
          </ColoredLinks>
          <Box textAlign="center">
            <Text opacity={0.5}>###</Text>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { Layout };
