import { format } from 'date-fns';
import * as React from 'react';
import Countdown from 'react-countdown';
import { Box, Heading } from 'rebass/styled-components';

type TourCountdownProps = {
  launchDate: Date;
};

const TourCountdown: React.FC<TourCountdownProps> = ({ launchDate }) => (
  <Countdown
    date={launchDate}
    renderer={({ props, completed, days, hours, minutes, seconds }) =>
      completed ? null : (
        <Box>
          <Heading marginTop={0}>
            <small>$NATE launches on Polygon</small>
            <Heading fontSize={5} marginY={1}>
              {format(new Date(props.date ?? 0), `ccc, LLL d K:mm bb zzz`)}
            </Heading>
            <small>(9pm LA / MIDNIGHT NYC)</small>
          </Heading>

          <Heading fontSize={6} marginTop={3}>
            {days} d &middot; {hours} hr &middot; {minutes} min &middot;{' '}
            {seconds} sec
          </Heading>
        </Box>
      )
    }
  />
);

export { TourCountdown };
