import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { profile } from '../assets/images';
import { SeoQuery } from '../generated/graphqlTypes';

type SeoProps = {
  title?: string | null;
  /**
   * Allows the default `titleTemplate` to be overridden.
   */
  titleTemplate?: string;
  description?: string | null;
  image?: string;
  pathname: string;
  article?: boolean;
  date?: string;
  dateModified?: string;
  keywords?: string[];
};

/**
 * Based on https://www.gatsbyjs.org/docs/add-seo-component/
 */
const Seo: React.FC<SeoProps> = ({
  title = '',
  titleTemplate,
  description = '',
  image,
  pathname,
  article = false,
  date,
  dateModified,
  keywords = [],
  ...restProps
}) => {
  const data = useStaticQuery<SeoQuery>(query);
  const siteMetadata = data.site?.siteMetadata;

  const seo = {
    title: title || siteMetadata?.defaultTitle,
    description: description || siteMetadata?.defaultDescription,
    image: `${siteMetadata?.siteUrl}${image || siteMetadata?.defaultImage}`,
    url: `${siteMetadata?.siteUrl}${pathname || '/'}`,
    keywords,
  };

  let renderedSchema: React.ReactNode = null;
  if (article) {
    const blogPostingSchema = {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': seo.url,
      },
      headline: seo.title,
      url: seo.url,
      author: {
        '@type': 'Person',
        name: 'Nathan H. Leung',
        image: profile,
        url: 'https://www.natecation.com',
      },
      creator: {
        '@type': 'Person',
        name: 'Nathan H. Leung',
        image: profile,
        url: 'https://www.natecation.com',
      },
      publisher: {
        '@type': 'Person',
        name: 'Nathan H. Leung',
        image: profile,
        url: 'https://www.natecation.com',
      },
      keywords: seo.keywords,
      ...(date
        ? {
            dateCreated: date,
            datePublished: date,
            dateModified: dateModified || date,
          }
        : {}),
      image: seo.image,
    };

    renderedSchema = (
      <script type="application/ld+json">
        {JSON.stringify(blogPostingSchema)}
      </script>
    );
  }

  return (
    <>
      <Helmet titleTemplate={titleTemplate || '%s · Natecation'} {...restProps}>
        <html lang="en" dir="ltr" />
        <title>{seo.title}</title>
        <meta name="description" content={seo.description ?? ''} />
        <meta name="image" content={seo.image} />
        <meta name="keywords" content={seo.keywords.join(',')} />

        {/* Open graph tags */}
        {seo.url && <meta property="og:url" content={seo.url} />}
        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}

        {/* Twitter card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {siteMetadata?.twitterUsername && (
          <meta
            name="twitter:creator"
            content={siteMetadata?.twitterUsername}
          />
        )}
        {seo.title && <meta name="twitter:title" content={seo.title} />}
        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}
        {seo.image && <meta name="twitter:image" content={seo.image} />}

        {/* Schema.org */}
        {renderedSchema}
      </Helmet>
    </>
  );
};

export { Seo };

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;
