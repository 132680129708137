import { Link } from 'gatsby';
import * as React from 'react';
import { Flex } from 'rebass/styled-components';
import styled from 'styled-components';

const NavLink = styled.h4`
  font-size: 1rem;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (min-width: 992px) {
    font-size: 1.1rem;
    padding-left: 3rem;
    padding-right: 0;
  }

  & > a {
    color: inherit;
    box-shadow: none;
    text-decoration: none;
    opacity: 0.25;

    &:hover {
      opacity: 1;
    }
  }
`;

const NavLinks: React.FC = () => (
  <Flex alignItems="center" justifyContent={['center', 'flex-end']}>
    <NavLink>
      <Link to="/tour">Tour</Link>
    </NavLink>
    <NavLink>
      <Link to="/trips">Trips</Link>
    </NavLink>
    <NavLink>
      <Link to="/now">Now</Link>
    </NavLink>
  </Flex>
);

export { NavLinks };
