const createJustifiedLayout = require('justified-layout');
const { throttle } = require('lodash');

/**
 * Selects all galleries
 */
function getAllGalleries() {
  const galleries = document.querySelectorAll('.natecation-gallery');
  return galleries;
}

/**
 * Creates justified gallery layouts;
 */
function createJustifiedLayouts(galleries) {
  galleries.forEach(gallery => {
    let images = [];
    try {
      images = JSON.parse(decodeURIComponent(gallery.dataset.images));
    } catch {}

    const parentElement = gallery.parentElement;
    // Default to 768 if we can't read the parent's `offsetWidth`.
    const width = parentElement ? parentElement.offsetWidth : 768;

    const justifiedLayout = createJustifiedLayout(
      images.map(it => 1 / it.aspectRatio),
      {
        containerWidth: width,
        containerPadding: {
          top: 16,
          left: 0,
          right: 0,
          bottom: 54,
        },
        boxSpacing: {
          horizontal: 28,
          vertical: 54,
        },
        targetRowHeight: 240,
        targetRowHeightTolerance: 0.5,
      }
    );

    gallery.style.height = `${justifiedLayout.containerHeight}px`;
    const galleryRelative = gallery.firstElementChild;
    const relativeChildren = justifiedLayout.boxes.map((box, index) => {
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.top = `${box.top}px`;
      div.style.left = `${box.left}px`;
      div.style.height = `${box.height}px`;
      div.style.width = `${box.width}px`;
      const figure = document.createElement('figure');
      const { src, alt, srcset } = images[index];

      const img = document.createElement('img');
      img.src = src;
      img.alt = alt;
      img.title = alt;
      img.srcset = srcset.join(', ');
      img.style.width = '100%';
      img.style.height = '100%';
      img.style.marginBottom = '0.125rem';
      figure.appendChild(img);

      const figcaption = document.createElement('figcaption');
      figcaption.innerText = alt;
      figure.appendChild(figcaption);

      div.appendChild(figure);
      return div;
    });
    galleryRelative.replaceChildren(...relativeChildren);
  });
}

module.exports = () => {
  const galleries = getAllGalleries();

  window.addEventListener(
    'resize',
    throttle(() => createJustifiedLayouts(galleries), 500)
  );

  createJustifiedLayouts(galleries);
};
