import { css } from 'styled-components';
import { rhythm } from '../../utils/typography';

const inputStyle = css`
  display: inline-block;
  font-size: ${rhythm(3 / 4)};
  font-weight: normal;
  border-style: solid;
  border-color: #f0f0f0;
  border-radius: 0.4375rem;
  border-width: ${rhythm(1 / 8)};
  padding: ${rhythm(1 / 4)};
  padding-left: ${rhythm(3 / 4)};
  margin-top: 10px;
  margin-right: 10px;
  flex: 1;

  &:focus {
    border-color: #e0e0e0;
    outline: none;
  }
`;

export { inputStyle };
